// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap");
// Plus imports for other components in your app.
@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css");
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$oneworld-primary: mat.define-palette(mat.$red-palette);
$oneworld-accent: mat.define-palette(mat.$red-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$oneworld-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".

$oneworld-theme: mat.define-light-theme((color: (primary: $oneworld-primary,
        accent: $oneworld-accent,
        warn: $oneworld-warn,
      ),
    ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($oneworld-theme);

/* You can add global styles to this file, and also import other style files */
.mdc-linear-progress__bar-inner {
  border-top-width: var(--mdc-linear-progress-track-height, 10px) !important;
}

.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(255, 11, 0, 0.08) !important;
}

.mat-mdc-radio-button .mdc-radio {
  padding: calc((var(--mdc-radio-state-layer-size, 30px) - 20px) / 2) !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-mdc-form-field-infix {
  min-height: 35px !important;
}

.mat-drawer-content {
  margin-left: 0rem !important;
}

* {
  font-family: "Inter", sans-serif;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  // font-family: Roboto, "Helvetica Neue", sans-serif;
  font-family: "Inter", sans-serif;
}

/* Importing Bootstrap SCSS file. */
// @import "bootstrap/scss/bootstrap";

/* :root {
  --primary-color: #ff0b00;
  --secondary-color: #c17cd2;
  --gradient-color1: #ff15cc;
  --gradient-color2: #0019bf;
  --white-color: white;
  --bg-secondary-color: #f6f8fc;
  --text-muted: #6c757d;
} */
 /* FONTS & BACKGROUND COLOR STYLE */
.text-primary {
  color: #ff0b00 !important;
}

.icon-active {
  color: #ff0b00 !important;
}

.a-active {
  color: #ff0b00 !important;
  border-bottom: 3px solid #ff0b00;

  mat-icon {
    color: #ff0b00 !important;
  }

  span {
    color: #ff0b00 !important;
  }
}

.bg-primary {
  background-color: #ff0b00 !important;
}

.bg-light {
  background-color: #f8f6fb !important;
}

.text-second {
  color: #e5e5e5;
}

.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
h3 {
  font-size: 1.75rem !important;
}
/*  text-color */
h1,h2,h3,h4,h5,h6,p,span,a{
  font-family: "Inter", sans-serif !important;
}
.text-muted-9{
  color: #333333 !important;
}
.text-muted-8{
  color: #666666 !important;
}
.text-muted-1{
  color: #ababab !important;
}
.medium-cl {
  color: #0000009e;
}
.fs-16{
  font-size: 16px !important;
}
.fw-400{
  font-weight: 400 !important;
}
.fw-500{
  font-weight: 500 !important;
}
.fw-600{
  font-weight: 600 !important;
}

/* BUTTON STYLE */
.btn-primary {
  background-color: #ff0b00 !important;
  color: white !important;
  border-radius: 8px !important;
  border: none;
}

.btn-primary:hover {
  background-color: #ff0b00 !important;
  color: white;
}

.btn-primary:active {
  background-color: #ff0b00 !important;
  color: white;
}

.btn-secondary {
  background-color: white !important;
  border-radius: 8px !important;
  color: black !important;
  border-color: black !important;
}

.btn-secondary:hover {
  background-color: white !important;
  border-radius: 8px;
  color: black;
}

.btn-secondary:active {
  background-color: white !important;
  border-radius: 8px;
  color: black;
}
.icon-btn {
  //background: rgba(255, 11, 0, 0.08);
  // color: #ff0b00;
  padding: 0px;
  border: none;
  border-radius: 50%;
  height: 35px;
  width: 35px;
}
.icon-btn {
  background: rgba(255, 11, 0, 0.08);
  color: #ff0b00;
  padding: 0px;
  border: none;
  border-radius: 50%;
  height: 35px;
  width: 35px;
}
.create-btn {
  position: absolute;
  color: white;
  bottom: 0px;
  right: 0px;
  border: none;
  border-radius: 0px;
  width: 250px;
  font-size: 14px;
}
.btn-sec {
  background-color: #acacac;
  color: black;
  margin: 10px;
  width: 50%;
  font-size: 14px;
}
/* FOCUS OF BUTTON AND FORM */
button:focus {
  outline: none;
}

.form-control:focus {
  box-shadow: none;
}
.navbar-toggler:focus {
  box-shadow: none !important;
}

/* CARD CSS */
.general-card {
  background: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 8px;
  margin-top: 2rem;
}
.img-card {
  height: 250px;
  position: relative;
}


/* .mat-mdc-tab-label-container {
  border-bottom: 5px solid rgba(255, 11, 0, 0.08) !important;
} */
/* .mat-mdc-tab-labels {
  border-bottom: 5px solid rgba(255, 11, 0, 0.08) !important;
} */




.form-select {
  background-color: transparent;
  border-radius: 30px;
  border: none;
}

.cover {
  /* background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.92),
      rgba(255, 255, 255, 0.92)
    ),
    url("../../../../assets/images/cover.png"); */
  border-radius: 11px;
  background-size: cover;
  background-repeat: no-repeat;
}

/* MATERIAL STYLE */
.mat-elevation-z8 {
  box-shadow: none !important;
}
/*  MATERIAL TEXT FEILD */
.mat-mdc-text-field-wrapper {
  background-color: #fff3f2 !important;
}
/* MATERIAL TAB FEILD */
.mdc-tab--active {
  border-bottom: 4px solid #ff0b00 !important;
}

.mat-mdc-tab-list{
  flex-grow: 0!important;
}

.mat-mdc-tab-label-container {
  border-bottom: 5px solid rgba(255, 11, 0, 0.08) !important;
}
.mat-mdc-tab-link-container{
  border-bottom: 5px solid rgba(255, 11, 0, 0.08) !important;
}
.mat-mdc-tab-header.mat-mdc-tab-nav-bar-stretch-tabs .mat-mdc-tab-link {
    flex-grow: 0!important;
}
.mdc-tab--active {
  border-bottom: 4px solid #ff0b00 !important;
}
.mat-mdc-dialog-surface {
  padding: 0 !important;
}
/* CUSTOM PROGRESS BAR */
.custom-progress-bar {
  height: 10px;
}

.custom-progress-bar .mat-progress-bar-fill::after {
  height: 10px;
}

.custom-progress-bar.mat-mdc-progress-bar{
  --mdc-linear-progress-active-indicator-color: green;
}

.mdc-linear-progress__bar-inner {
  border-top-width: 10px !important;
}
/* MATERIAL DRAWER AND CONTAINER*/
.mat-drawer {
  position: fixed;
  top: 70px;
}
.mat-drawer-container {
  overflow: visible !important;
}
.mat-mdc-tab .mdc-tab-indicator__content--underline {
  border: none !important;
}

/* MATERIAL STYLE END */


/* CUSTOM PADDING , MARGIN AND WIDTH */
.mx-m-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}
.width-50{
  width: 50%;
}
.p-m-3 {
  padding: 1rem;
}

.w-m-25 {
  width: 25%;
}

.pe-m-5 {
  padding-right: 3rem !important;
}

.ps-m-5 {
  padding-left: 3rem !important;
}

.w-mm-25 {
  width: 40%;
}
.margin-md-3 {
  margin-left: 3rem;
}


 /*  mat drawer */

.drawer-true {
  min-width: 11rem !important;
  transition: 0.25s all;
  display: block;
}

.drawer-false {
  min-width: 5rem !important;
  transition: 0.25s all;
  display: block;
}

.content-expand {
  margin-left: 11rem;
  transition: 0.25s all;
}

.content-noexpand {
  margin-left: 5rem;
  transition: 0.25s all;
}


ul li {
  list-style-type: none;
  color: #acacac;
  margin-top: 40px;
}

ul {
  padding-left: 0px !important;
}

.panel-card {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  /*   margin: 3rem 3rem 0rem 3rem; */
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 10px;
  background-color: white;
  /*   overflow-y: auto;
  height: calc(100vh - 12vh - 3rem); */
}

.panel-content {
  width: 100%;
  padding: 2rem 2rem 2rem 2rem;
  overflow-y: auto;
  height: calc(100vh - 14vh);
  background-color: #F5F5F5;
}

.active {
  border-bottom: 3px solid #ff0b00;
  color: #ff0b00 !important;

  mat-icon {
    color: #ff0b00 !important;
  }
}

.nav-item {
  cursor: pointer;
}

.toggle-bt {
  left: 0px;
  transition: 0.25s all;
  font-size: 25px;
}

.toggle-bt-open {
  // left: 155px;
  left: 50px;
  transition: 0.25s all;
  font-size: 25px;
}

.mobile-sidebar {
  display: none;
}


.desktop-sidebar {
  display: block;
}

#menuContent {
  z-index: 999;
  top: 8vh;
}


.img-logo {
  width: 80px;
}

.menu0icon {
/*   font-size: 40px !important; */
  color: #ff0b00 !important;
  height: 3rem !important;
  width: 3rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
}

.example-container {
  //height: 100vh;
  overflow: visible;
}

.header-t {
  position: sticky;
  top: 0px;
  z-index: 999;
  // min-height: 12vh;
  // max-height: 12vh;
}

.right-panel {
 // width: 300px;
  height: calc(100vh - 14vh);
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.r-panel {
  position: relative;
}

.content {
  height: 68vh;
  overflow-y: auto;
}

.head {
  display: flex;
  background-color: aliceblue;
  justify-content: space-between;
}

.r-footer-panel {
  background-color: aliceblue;
  position: absolute;
  top: calc(88vh - 110px);
  //height: 60px;
  width: 300px;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  align-items: center;
}
/* NAV LINK */
.nav-link {
  display: grid;
  place-items: center;
  font-size: 14px !important;
  padding: 1rem 1rem;
  color: black;
  line-height: 1.5rem !important;
}
.nav-link:hover span {
  color: #ff0b00;
}

.nav-link:hover mat-icon {
  color: #ff0b00;
}

/* material card */
.mat-mdc-card{
  border-radius: 12px !important;
  box-shadow: none !important;
    padding: 1rem !important;
    .cover-img{
     border-radius: 8px;
    }
    .job-profile{
      width: 100%;
    }
}
mat-card:hover{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}
.home-page-card{
  min-height: 200px;
}
.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}
/* buttons material */
.primary-mat-btn{
  background-color: #ff0b00 !important;
  color: white !important;
  border-radius: 8px !important;
  border: none;
  font-weight: 300;
}
.filter-btn{
  background-color: white !important;
  color: #666666 !important;
  z-index: 0;
}
.filter-btn:hover{
  background-color: white !important;
  border: 1px solid gray !important;
}
.mat-icon-btn{
  display: flex;
    flex-direction: row-reverse;
    padding: 0px 25px 0px 25px !important;
}

/* userprofile */
.profile{
  .profile-img{
    height: 400px;
    object-fit: fill;
    border-radius: 12px;
    width: 100%;
  }
  .section-img{
    height: 300px;
    object-fit: fill;
    border-radius: 12px;
    width: 100%;
  }
  .edit-icon{
    position: absolute;
    top: 10px;
    right: 10px;
    color: #ff0b00;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* material modal css */
.mat-mdc-dialog-container .mdc-dialog__surface{
  border-radius: 18px !important;
    padding: 1rem !important;
}
.mat-mdc-dialog-actions{
  padding: 0px 0px 20px 25px !important;
}
.mat-mdc-tab-group{
  z-index: 0;
  position: relative;
}
.mat-mdc-text-field-wrapper{
  border-top: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-radius: 8px !important; 
  background-color: white !important;
}
.cdk-overlay-pane{
  max-width: 90vw !important;
}
.close-modal-icon{
  position: relative;
}
@media(max-width:768px){
  .close-modal-icon{
    position: absolute;
    top: 5px;
    right: 15px;
  }
  .mat-mdc-dialog-title{
    padding: 0px !important;
  }
  mat-dialog-content{
    padding-left: 0px !important;
    padding-right: 12px !important;
  }
}
/* image css on existing and linked card list modal */
.existingcard-img{
height: 70px;
width: 100px;
border-radius: 8px;
}
.list-grid{
  height: 70px;
  display: flex;
  align-items: center;
}
/* trueself-template-view css */
.trueself-template-view-image{
 height: 170px;
 width: 100%;
 object-fit: cover;
 border-radius: 8px;
}
/* OTHER STYLES */
a {
  text-decoration: none;
}
.width-100{
  width: 100% !important;
}
.pointer {
  cursor: pointer;
}

.border-primary {
  border: 2px solid #ff0b00 !important;
}
.border-le {
  border-left: 3px solid #ff0b00;
}
.border-1{
  border: 1px solid black;
  border-radius: 8px;
}
.border-11{
  border: 1px solid #d9d8d8;
  border-radius: 8px;
}
  .double-border {
    border: 8px double #d4d4d4;
    border-radius: 20px;
}
.custom-shadow{
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.single-border {
  border: 4px solid #d4d4d4;
  border-radius: 20px;
}
.search-box{
  border-radius: 12px !important;
    border: none !important;
}
.text-underline {
  text-decoration: underline;
}
.br-12{
  border-radius: 12px !important;
}

.card-title{
  font-size: 18px !important;
  font-weight: 500 !important;
}
/* Apply job card css */
.cover-img{
  width: 130px;
  height: 100%;
  border-radius: 8px;
}

.coment-box{
  height: 30px;
    width: auto;
    padding: 10px !important;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* achieve detaile page */
.achieve_bg_image{
  width: 500px;
  height: 200px;
}
.achieve-detail-card{
  height: 300px;
}


/* CITY DETAIL PAGE */
.cover-img-city-detail{
  width: 100px;
  height: 70px;
  border-radius: 8px;
}
.city-detail-card{
  height: 300px;
  position: relative;
  .following-text{
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

/* connect-detail PAGE */
.connect-detail-card{
  height: 300px;
  position: relative;
  .following-text{
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
  }
}
.post-review-card{
  background-color: transparent !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

/* webkit scrollbar */

::-webkit-scrollbar {
  width: 5px;  /* Width of the entire scrollbar */
  height: 5px; /* Height of the horizontal scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1;  /* Color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #888;  /* Color of the scrollbar handle */
  border-radius: 6px; /* Roundness of the scrollbar handle */
}

::-webkit-scrollbar-thumb:hover {
  background: #555;  /* Color when hovering over the scrollbar handle */
}

/* responsive on panel open */

.wrapper-panel{
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
}
.item-panel{
   flex: 0 0 20vw;
   width: 20vw;
}

@media screen and (max-width: 768px) {
  .item-panel{
    flex: 1 0 auto !important;
   width: 100% !important;
 }
  .border-le {
    border-left: none;
  }
  .achieve-detail-card{
    height: auto;
  }
  .mat-mdc-card .job-profile{
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  .profile{
    .profile-img{
      height: auto !important;
    }
  }
  .mat-drawer-content {
    display: contents !important;
  }

  .mat-drawer.mat-drawer-side {
    z-index: 9 !important;
  }
/*   padding,margin,width */
  .w-mm-25 {
    width: 150px;
  }

  .pe-m-5 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .ps-m-5 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .w-m-25 {
    width: 80px;
  }

  .mx-m-3 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .p-m-3 {
    padding: 0.25rem;
  }
  

}

/* sidebar and panel */
@media screen and (max-width: 768px) {
 

  .toggle-bt {
    // left: 280px !important;
    z-index: 99 !important;
    min-width: 50px;
    margin: 0px !important;
    position: fixed;
  }

  .panel-card {
    /*  margin: 3rem 1rem 1rem 1rem !important; */
    padding: 0.25rem !important;
  }

  .panel-content {
    padding: 2rem 1rem 1rem 1rem;
  }

  .mat-drawer-content {
    display: contents !important;
  }

  .margin-md-3 {
    margin-left: 0.8rem;
  }

  .content-expand {
    margin-left: 0rem !important;
    transition: 0.25s all;
  }

  .content-noexpand {
    margin-left: 0rem !important;
    transition: 0.25s all;
  }

  .drawer-false {
    min-width: 0rem !important;
    transition: 0.25s all;
    display: none;
  }

  .drawer-true {
    min-width: 0rem !important;
    transition: 0.25s all;
    display: none;
  }

  .mobile-sidebar {
    min-width: 14rem;
    display: block;
  }

  .mobile-sidebar .toggle-bt {
    position: fixed;
  }

  .desktop-sidebar {
    display: none !important;
    visibility: hidden !important;
  }

  .toggle-bt {
    position: absolute !important;
  }

}

@media screen and (max-width: 992px) {

  .r-panel {
    position: absolute !important;
    right: 0px;
    bottom: 0px;
    top: 10vh;
    z-index: 99;
  }

/*   .r-footer-panel {
    width: 250px !important;
  } */

  .right-panel {
    width: 300px !important;
  }
}

/* avatar image in contibute */
.avatar-img{
  border-radius: 50%;
  width: 50px;
  height: 50px;
}
.list-card-image{
  width: 200px;
  max-height: 125px;
  min-height: 125px;
 }
 @media screen and (max-width: 768px) {
  .list-card-image{
    width: 100%;
   }
 }

 .hire-btn{
  border: 1px solid black !important; 
  border-radius: 0px;
  background-color: white;
}

             /* landing page style */


             .text-slate-400-landing{
              color: rgb(148 163 184/ 1) !important;
          }
          .bg-slate-400-landing{
              background-color: rgb(248 250 252/1)!important; 
          }
          section{
              margin-top: 5rem;
              padding-top: 1rem;
              padding-bottom: 1rem;
          }
          .home-sec{
              padding-top: 5rem;
          }
/* HEADER */
.landing-header.scrolled {
  background-color: white;
   box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
 
}
.landing-header.notscroll {
//  background-color: transparent;
  background-color: rgb(248 250 252/1); 

}
.landing-header{
   padding: 25px 10px;
   position: sticky;
   z-index: 9;
  top: 0px;
   .landing-logo{
      width: 180px;
  }
  .landing-btn{
      font-size: 14px;
      padding: 0px;
      font-weight: 700;
      padding: 0px 15px;
      // border-radius: 8px;
  }
  .listing-header{
      display: flex;
      align-items: center;
      margin-bottom: 0px;
      margin-top: 0px;
      font-size: 16px;
  }
  .listing-header-item{
      margin-bottom: 0px;
      margin-top: 0px;
      margin-left: 25px;
  }

  .divider {
    font-size: 28px;
  }
  .link-item{
      color: rgb(22 28 45/1);
      font-weight: 500;
  }
  .link-item:hover{
      color: #ff0b00;
  }
  }
/* MOBILE HEADER */
  .landing-mobile-header{
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
      padding: 15px 10px;
      position: sticky;
      top: 0px;
      z-index: 9;
      height: 50px;
      .landing-logo{
          width: 150px;
      }
      
      
  }
/*   SIDEBAR */
.sidebar-landing{
  position: fixed;
  height: calc(100vh - 50px);
  top:50px;
  width: 100%;
  transform: translateX(-100%);
  transition: 1s all ease-in-out;
  background-color: white;
  z-index: 99;
  .listing-mobile-header{
      display: block;
      //align-items: center;
      margin-bottom: 0px;
      margin-top: 0px;
      margin-left: 15px;
     li{
      margin-bottom: 0px;
      margin-top: 10px !important;
      
  }
  }
 
  .link-item{
      color: rgb(22 28 45/1);
      font-weight: 500;
  }
  .link-item:hover{
      color: #ff0b00;
  }
  .landing-btn{
      font-size: 12px;
      padding: 0px;
      font-weight: 700;
      padding: 0px 15px;
      // border-radius: 8px;
  }
}
.sidebar-landing.active-sidebar{
  transform: translateX(0);
  transition: 1s all ease-in-out;
}
  .landing-title{
 position: relative;
display: inline-block; 
font-size: calc(1.325rem + .9vw) !important;
  }
   .landing-title::after {
      content: '';
      position: absolute;
      bottom: -8px;
      left: 25%;
      width: 50%;
      border-top: 2px solid red;
    } 
/* BANNER SECTION */
.banner-box{
  margin-top: 0rem;
  h1{
      font-size: 40px;
      font-weight: 600;
  }

}
.banner-img1{
  width: 100%;
  border-radius: 5px;
}
/*  FAQ*/
.mat-expansion-panel{
  box-shadow: none !important;
  margin-bottom: 0px;
 }
 .mat-expansion-panel{
  border-bottom: 1px solid rgb(186, 185, 185);
}
   /*  HOW IT WORKS */
   .nav-tabs .nav-item  .nav-link.active{
    background-color: #ff0b00 !important;
    border-color: none !important;
    color: white !important;
 }
 .nav-tabs .nav-item  .nav-link:hover{
  background-color: #ff0b00 !important;
  color: white !important;
 }
.nav-tabs{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: white;
    border-radius: 30px;
    width: 80%;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
    .nav-item{
        flex: 1 1 auto;
        margin: 0px;
        color: black;
    }
    .nav-link{
            padding: 6px 20px;
            width: 100%;
    }
}
.how-accor{
    background-color: transparent !important;
}
.mat-expansion-panel-header-title{
    font-weight: 600 !important;
    font-size: 17px !important;
}
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover{
    background-color: white !important;
}
.how-accor.mat-expanded{
    border-left: 3px solid #ff0b00;
    transition: .1s all ease-in-out;
}
.slideimgMobile{
    width: 100%;
   transition: 1s all ease-in-out;
  transform: translateX(0px);
   border-radius: 12px; 
}
.slideimg{
    width: 100%;
   // position: absolute;
  transition: 1s all ease-in-out;
 // display: none;
  transform: translateX(700px);
  border-radius: 12px;
}
.slide-img-1{
    width: 100%;
   // display: block !important;
    transform: translateX(0) !important;
    transition: 1s all ease-in-out;
}
.slide-img-2{
    width: 100%;
    transform: translateX(0px) !important;
    transition: 1s all ease-in-out;
    position: absolute;
    left: 0;
}
.slide-img-3{
    width: 100%;
    transform: translateX(0px) !important;
    transition: 1s all ease-in-out;
    position: absolute;
    left: 0;
}
.slide-img-4{
    width: 100%;
    //display: block !important;
    transform: translateX(0px) !important;
    transition: 1s all ease-in-out;
    position: absolute;
    left: 0;
}
.slide-img-5{
  width: 100%;
  //display: block !important;
  transform: translateX(0px) !important;
  transition: 1s all ease-in-out;
  position: absolute;
  left: 0;
}
.tab-content{
    margin-top: 3rem;
}

.carousell {
    width: 100%;
    overflow: hidden;
  }
  
  .carousell-inner {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 100%;
    position: relative;
  }
  
  .carousell-item {
    flex: 0 0 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
   // background-color: #f0f0f0;
  }
  /*  WHY JOIN SECTION */
  .whyjoin-card{
    margin-top: 1rem;
    padding: 10px 25px 10px 0px;
    .img-card{
        background-color: white;
        padding: 10px;
        border-radius: 8px;
        width: fit-content;
    height: fit-content;
        i{
            font-size: 25px;
        }
    }
    h3{
        border-left: 4px solid #ff0b00;
        padding-left: 8px;
        font-size: 20px !important;
    }
   }
/*  TESTOMIAL*/
.carousel-item .card-group {
  display: flex;
  gap: 20px;
  flex-flow: nowrap;
}

.carousel-item .testimonial-card {
  flex: 0 0 calc(33.333% - 15px);
  margin-bottom: 0;
  background-color: white;
  border: 1px solid rgb(186, 185, 185);
  border-radius: 12px;
  padding: 1rem;
  h4{
      font-weight: 600;
      font-size: 20px;
      color: black;
  }
  .profile-img{
      width: 50px;
      height: 50px;
      border-radius: 50%;
  }
  h3{
      font-weight: 500;
      font-size: 16px !important;
      margin-bottom: 0px !important;
  }
}

.carousel-control-prev,
.carousel-control-next {
  width: 3%;
  background-color: transparent;
  position: static;
  margin-top: 10px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: white;
  border: 1px solid rgb(186, 185, 185);
  border-radius: 50%;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
 
}
.carousel-inner::after {
  //display: none;
  visibility: hidden;
}

.carousel-control-prev-icon{
  background-image: none;
}
.carousel-control-next-icon{
  background-image: none;
}
.carousel-button-div{
  position: absolute;
  display: flex;
  width: 100%;
 justify-content: center;
}

/* FOOTER */
footer{
  margin-top: 5rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  .icon-social{
      background-color: #ff0b00;
      margin-right: 10px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      i{
          font-size: 20px;
      }
  }
   .footer-logo{
  width: 220px;
}
  h5{
      font-size: 20px !important;
      font-weight: 600;
  }
  .footer-link{
      text-decoration: none;
      color: rgb(148 163 184/ 1);
      cursor: pointer;
  }
  .footer-link:hover{
      color: #ff0b00;
  }
  .footer-list{
      margin-left: 0px;
      li{
          margin-top: 10px;
      }
  }
}
@media(max-width:768px){
  section{
      margin-top: 2rem;
  }
  .carousel-item .testimonial-card {
      flex: 0 0 calc(100% );
  }
  .carousel-control-next-icon, .carousel-control-prev-icon{
      height: 1rem;
  }
  .nav-tabs{
width: 100%;
.nav-link{
  padding: 6px 6px;
  width: 100%;
}
  }
  .banner-box{
      h1{
          font-size: 36px;
      }
  }
  .banner-img1{
      width: 75%;
  }
  .home-sec{
    padding-top: 3rem;
  }
}
/* mobile listing */
.visibility-lg{
  visibility: visible;
}
.visibility-md{
  visibility: hidden;
  display: none !important;
}

@media(max-width:768px){
  .visibility-lg{
    visibility: hidden;
    display: none !important;
  }
  .visibility-md{
    visibility: visible !important;
    display: flex !important;
  }
}

sup.custom {
  top: -2rem;
  font-size: 12px;
}

hr.custom {
  margin-top: 6px;
}